import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";

const History = () => {
    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             name
             sections{
                 about{
                    items{
                        data{
                            id
                            value
                        }
                        images
                    }
                 }

             }
         }
     }`)
    return (
        <section className="about-history overflow-hidden">
            <div className="container-fluid" id="checkContainer">
                <div className="row content-history">
                    <div className="col-12 text-center">     
                        <div className="row article-history">
                            <div data-aos="fade-right" className="col-12 d-lg-flex align-items-strech">
                                <div className="row">
                                    <div className="col-lg-6 col-10 offset-2 offset-lg-0 order-lg-1 order-2">
                                        <img src={realEstate.sections.about.items.images[0]} alt={realEstate?.name} />
                                    </div>
                                    <div className="col-lg-6 ps-lg-5 pe-lg-5 text-start order-lg-2 order-1">
                                        <h1 className="mb-xl-4 mb-2">01</h1>
                                        <p className="mb-lg-5 text-start">
                                            {getBrText(realEstate.sections.about.items.data[0].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>

                                        <h1 className="mb-xl-4 mb-2 mt-lg-5">02</h1>
                                        <p className="text-start">
                                            {getBrText(realEstate.sections.about.items.data[1].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row article-history">
                            <div data-aos="fade-left" className="col-12 d-lg-flex align-items-strech">
                                <div className="row">
                                    <div className="col-lg-6 pe-lg-5 col-10 offset-0 offset-lg-0 text-start order-1 order-lg-1">
                                        <h1 className="mb-xl-4 mb-2 mt-5 mt-lg-0" >03</h1>
                                        <p className="mb-lg-5">
                                            {getBrText(realEstate.sections.about.items.data[2].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>
                                        <h1 className="mb-xl-4 mb-2 mt-lg-5">04</h1>
                                        <p className="mb-lg-5 mb-0">
                                            {getBrText(realEstate.sections.about.items.data[3].value).map(element => (
                                                <>
                                                    {element}
                                                    <br className="d-none d-lg-block" />
                                                </>
                                            ))}
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-10 mb-lg-5 mb-lg-0 ps-lg-5 order-2 order-lg-2 offset-2 mt-5 mt-lg-0 offset-lg-0">
                                        <img src={realEstate.sections.about.items.images[1]} alt={realEstate?.name} />
                                    </div>                     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default History